import _ from 'lodash'
import React, {Component, Fragment} from "react"
import CSSTransitionGroup from 'react-addons-css-transition-group'
import ReactMarkdown from 'react-markdown'
import classnames from 'classnames'
import {ignore} from '../helpers.js'
import {UserBar} from './User.js'

class Home extends Component{
  constructor(props){
    super(props)
    let {mode, about, onNavigate} = props
    this.transition = 'forward'
    this._bound = []
    // custom markdown renderer to shim in our click handler for local links
    this._renderers = {link:(props) => <a href={props.href} onClick={this.props.onNavigate}>{props.children}</a>}
  }

  componentWillMount(){
    _.each(this._bound||[], m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}

  componentWillUnmount(){}

  componentWillReceiveProps(next){
    // catch mode changes and flag their direction so we can get the transition right
    let newMode = next.mode != this.props.mode,
        newSect = next.section != this.props.section;
    this.transition = newMode && next.mode=='about' ? 'backward'
                    : newSect && next.section=='intro' ? 'backward'
                    : 'forward';
  }

  render(){
    let {mode, section, about} = this.props
    const renderers = {link:(props) => <a href={props.href} onClick={this.props.onNavigate}>{props.children}</a>}

    return (
      <CSSTransitionGroup component="article" id="home" transitionName={this.transition} transitionEnterTimeout={666} transitionLeaveTimeout={333}>
        {mode=='about' && this.props.children /* the nav buttons */}
        {mode=='about' &&
        <section className={section} key={section}>
          <Markdown source={about[section]} renderers={this._renderers}/>
        </section>
        }
      </CSSTransitionGroup>
    )
  }
}

const Logo = (props) => {
  let {mode, section} = props,
      inSubsection = mode=='about' && section!='intro',
      logoClass = classnames({back:inSubsection, active:mode!='about'});

  return (
    <header id="logo" className={logoClass} onMouseDown={ignore}>
      <div className="wordmark" onClick={props.onNavigate}>The New Bagehot Project</div>
      <div className="mobile-menu" onClick={props.onMenu}></div>
    </header>
  )
}

class Markdown extends Component{
  render(){
    const Rootless = (props) => <Fragment>{props.children}</Fragment>,
          renderers = _.extend({root:Rootless}, this.props.renderers);

    return <ReactMarkdown {...this.props}
              escapeHtml={false}
              disallowedTypes={['linkReference']}
              unwrapDisallowed={true}
              renderers={renderers}
            />
  }
}


module.exports = {Home, Logo}