import {ignore} from '../helpers.js'

var Matrix = function(elt){
  var dom = $(elt)
  var that = {
    init:function(){
      dom.find('.expandable h1').on('click', that.toggle)
                                .on('mousedown', ignore)

      let ref = new URL(document.referrer || location.origin)
      if (document.referrer && ref.host == location.host){
        $('nav .back a').attr('href', ref.pathname)
                        .on('click', e => {history.back(); ignore(e)} )
      }

      // race condition between page load & measurement (due to font loading?)
      // brute force ‘fix’ by recalculating height repeatedly…
      that.updateHeight()
      _.delay(that.updateHeight, 500)
      _.delay(that.updateHeight, 1500)

      return that
    },

    updateHeight: () => {
      let rowHeight = dom.find('.top.row').outerHeight()
      $("nav.compare .back a").height(rowHeight)
    },

    toggle: (e) => {
      $(e.target).closest('.expandable')
                 .toggleClass('collapsed')
    }
  }

  return (dom.length==0) ? {} : that.init()
}

module.exports = Matrix