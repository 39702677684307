import _ from 'lodash'
import React, {Component, Fragment} from "react"
import classnames from 'classnames'
import Filters from './Filters.js'
import Results from './Results.js'
import {SearchBar, SearchField} from './Search.js'
import {UserBar} from './User.js'
import {Home, Logo} from './Home.js'
import {ignore, slugify, scrollPage, isMobile, getJSON} from '../helpers.js'

class App extends Component{
  constructor(props){
    super(props)

    let {mode, section, kind, query, search, taxonomy, corpus, types} = props
    this.state = {mode, section, kind, query, search, corpus, hasHistory:false, user:{}, menu:false}
    this._bound = ['navigate', 'arrived', 'refilter', 'research', 'updateTitle', 'updateUser', 'revealSearchBar', 'revealMobileFilters']
    this.searchBarRef = React.createRef()
    this.searchTimer = null
  }

  componentWillMount(){
    _.each(this._bound||[], m => this[m] = this[m].bind(this))
  }

  componentDidMount(){
    $(window).on('popstate', this.arrived)
    this.updateUser()
  }

  componentWillUnmount(){
    $(window).off('popstate', this.arrived)
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateTitle()

    if (prevState.search != this.state.search){
      clearTimeout(this.searchTimer)
      if (this.state.search){
        let q = this.state.search.toLowerCase()
        this.searchTimer = setTimeout( () => {
          getJSON(`/api/search/${q}`, (err, corpus) => {
            this.setState(err ? {search:prevState.search} : {corpus})
          })
        }, 750)
      }else{
        getJSON(`/api/corpus/${this.state.kind}`, (err, corpus) => {
          if (!err) this.setState({corpus})
        })
      }

    }

    if (prevState.kind != this.state.kind){
      getJSON(`/api/corpus/${this.state.kind}`, (err, corpus) => {
        this.setState(err ? {kind:prevState.kind} : {corpus})
      })
    }
  }

  /* -- url history management -- */

  parseURL(url){
    let path = url.replace(/\?.*$/,'').split('/').slice(1),
        mode = path[0] || 'about',
        valid = _.map(_.flatMap(this.props.taxonomy, 'tags'), 'slug'),
        query = _.intersection(valid, _.split(path[2], '+'));

    let search = _.get(url.match(/[\?&]q=([^&]+)/), 1, '')
                  .replace(/[^\w\+]/g, '') || null

    return (mode=='about') ? {mode, section:path[1] || 'intro', query:null, search:null}
         : (mode=='find')  ? {mode, kind:path[1] || 'all', query, search}
         : {mode}
  }

  arrived(e){
    let href = location.pathname + location.search,
        hasHistory = e.state=="_i_n_t_e_r_n_a_l_",
        loc = _.extend(this.parseURL(href), {hasHistory});
    this.setState(loc)
  }

  goTo(url, silently){
    let loc = this.parseURL(url),
        changes = _.extend(loc, silently ? {} : {hasHistory:true}),
        updateHistory = history[silently ? 'replaceState' : 'pushState'].bind(history)
    updateHistory("_i_n_t_e_r_n_a_l_", '', url)
    this.setState(changes)
  }

  switchTo(url){
    this.goTo(url, 'silently')
  }

  backTo(url){
    if (this.state.hasHistory) history.back()
    else this.goTo(url)
    this.searchBarRef.current.dismiss({type:'click'})
  }

  /* -- click handlers for /about & /find sections -- */

  navigate(e){
    let href = e.target.getAttribute('href') || '/'
    if(isMobile()) return window.location = href

    if(href=='/') this.backTo('/')
    else this.goTo(href)
    ignore(e)
  }

  revealSearchBar(){
    this.goTo('/find/all')
    this.searchBarRef.current.expand()
  }

  revealMobileFilters(){
    this.setState( ({menu}) => ({menu:!menu}))
  }

  refilter(changes){
    let {mode, section, query, search} = this.state,
        {kind, selected} = changes,
        args = search ? `?q=${search}` : '',
        inMode = mode=='find';

    if (kind){
      let filters = _.isArray(query) ? _.join(query, '+') : ''
      this.goTo(`/find/${kind}/${filters}${args}`, inMode)
    }else if (!_.isEmpty(selected)){
      this.goTo(`/find/${this.state.kind}/${_.join(selected,'+')}${args}`, inMode)
    }else if (mode=='find'){
      if (_.isArray(selected)) this.goTo(`/find/${this.state.kind}${args}`, inMode)
      else this.backTo('/')
    }

    if (isMobile() && mode=='about') scrollPage({to:0})
  }

  research(search){
    let {mode, kind, query} = this.state,
        path = `/${mode}/${kind}`,
        inSearch = !!location.search.match(/q=./);
    if (query) path += `/${_.join(query,'+')}`
    if (search) path += `?q=${search}`
    this.goTo(path, !!search==inSearch)
  }


  /* -- markup -- */

  updateTitle(){
    if (typeof document=='undefined') return

    let {mode, section, kind, doc} = this.state,
        suffix = "The New Bagehot Project",
        prefix = mode=='about' ? {project:'About the project', site:'How to use'}[section]
               : mode=='find' ? _.find(this.props.typology, {kind}).title
               : undefined

    document.title = prefix ? `${prefix}: ${suffix}` : suffix
  }

  updateUser(){
    _.defer( () => {
      getJSON('/api/user', (err, resp) => this.setState({user:resp}))
    })
  }

  render(){
    let {about, taxonomy, typology, types} = this.props,
        {mode, section, kind, query, search, corpus, user, menu} = this.state;

    return (
      <div id="app" className={mode}>
        <main className={classnames({menu})}>
          <Logo mode={mode} section={section} onNavigate={this.navigate} onMenu={this.revealMobileFilters}/>
          <Home mode={mode} section={section} about={about} onNavigate={this.navigate}>
            <nav className="buttons">
              <div className="search" onClick={this.revealSearchBar}></div>
              <UserBar user={user} onAuth={this.updateUser}/>
            </nav>
          </Home>
          <Filters mode={mode} query={query} search={search} corpus={corpus} taxonomy={taxonomy} typology={typology} types={types} onFilter={this.refilter}/>
          <Results mode={mode} query={query} search={search} corpus={corpus} typology={typology}>
            <nav className={classnames({searching:!!search})}>
              <SearchBar ref={this.searchBarRef}/>
              <UserBar user={user} onAuth={this.updateUser}/>
              <SearchField q={search} onSearch={this.research}/>
            </nav>
          </Results>
        </main>
        <aside id="modal-root" ></aside>
      </div>
    )
  }
}

module.exports = App