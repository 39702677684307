import _ from 'lodash'
import React, {Component} from "react"
import classnames from "classnames"
import {slugify, ignore, parseTags, matchedQuery} from "../helpers.js"

class Filters extends Component{
  constructor(props){
    super(props)

    let collapsed = _.map(_.filter(this.props.taxonomy,
      ({subset, tags}) => subset=='filters' && _.isEmpty(_.intersection(_.map(tags, 'slug'), props.query))
    ), 'group');


    this.state = {collapsed}
    this._bound = ['tagSelected', 'groupSelected', 'kindSelected', 'clearSelection', 'toggleVisibility']
  }

  componentWillMount(){
    _.each(this._bound||[], m => this[m] = this[m].bind(this))
  }

  kindSelected(kind){
    this.props.onFilter({kind})
  }

  groupSelected(group, tags){
    let {query, taxonomy} = this.props,
        slugs = _.map(_.flatMap(taxonomy, 'tags'), 'slug'),
        counts = this.counts();

    if (!counts[group]) return

    let nonempty = _.filter(tags, ({title}) => counts[title]),
        active = _.filter(nonempty, ({slug}) => _.includes(query, slug)),
        inactive = _.filter(nonempty, ({slug}) => !_.includes(query, slug)),
        sel = _.some(inactive) ? _.concat(query, _.map(inactive, 'slug'))
            : _.some(active) ? _.difference(query, _.map(active, 'slug'))
            : query;
    this.props.onFilter({selected:_.intersection(slugs, sel)})
  }

  tagSelected(tag){
    let {query, taxonomy} = this.props,
        slugs = _.map(_.flatMap(taxonomy, 'tags'), 'slug'),
        toggled = _.includes(query, tag) ? _.without : _.concat,
        validated = sel => _.intersection(slugs, sel),
        selected = tag ? validated(toggled(query, tag)) : [];

    this.props.onFilter({selected})
  }

  clearSelection(){
    // setting to `null` will return to homepage, while `[]` will just deselect all tags
    this.props.onFilter({selected:[]})
  }

  toggleVisibility(group){
    this.setState(prev => {
      let {collapsed} = prev,
          toggled = _.includes(collapsed, group) ? _.without : _.concat;
      return {collapsed:toggled(collapsed, group)}
    })
  }

  counts(){
    let {articles} = this.props.corpus,
        query = matchedQuery(this.props.query, articles, 'title');

    let tags = _.countBy(_.flatMap(this.props.corpus.articles, ({tags, resources}) => {
      let count = _.size(resources || [1]),
          noActions = _.isEmpty(query.actions),
          actionMatch = _.some(_.intersection(tags, query.actions)),
          emitted = (noActions || actionMatch) ? tags : parseTags(tags, 'title').actions;
      return _.flatMap(emitted, tag => _.fill(Array(count), tag))
    }))

    let groups = _.countBy(_.flatMap(this.props.taxonomy, g => {
      let count = _.sum(_.values(_.pick(tags, _.map(g.tags, 'title'))))
      return _.fill(Array(count), g.group)
    }))

    return _.extend(tags, groups, {_query:query.all})
  }

  render(){
    let {typology, types, query, search} = this.props,
        {kind} = this.props.corpus,
        actions = _.filter(this.props.taxonomy, {subset:'actions'}),
        filters = _.filter(this.props.taxonomy, {subset:'filters'}),
        counts = this.counts();

    const FilterGroup = (props) => {
      let {title, tags} = props,
          collapsed = _.includes(this.state.collapsed, title);

      return (
        <section className={classnames('expandable', {collapsed})}>
          <h3 onClick={()=>this.toggleVisibility(title)}>{title}</h3>
          <TagList className="content" tags={tags}/>
        </section>
      )
    }

    const TagList = (props) => (
      <ul className={props.className}>{props.tags.map(tag => {
        let {title, slug} = tag,
            count = counts[title];
        return (
          <li key={slug} className={classnames({empty:!count, active:_.includes(query, slug)})}>
            <span className="tag" onClick={ count && (()=>this.tagSelected(slug)) } onMouseDown={ignore}>{title}</span>
            <span className="count">{count}</span>
          </li>
        )})}
      </ul>
    )

    return (
      <nav id='filters'>
        {!search &&
          <Typology kind={kind} kinds={typology} types={types} onSelection={this.kindSelected}/>
        }
        <p>{search ? "Filter search results by:" : "filtered by:"}</p>

        <div className="actions">
          <h1>Intervention Types <span className={classnames('clear', {active:_.some(counts._query)})} onClick={this.clearSelection} onMouseDown={ignore}>Clear all filters</span></h1>
          {actions.map( ({group, tags}) =>
            <section key={group}>
              <h2>
                <span className="group" onClick={()=>this.groupSelected(group, tags)}>{group}</span>
                <span className="count">{counts[group]}</span>
              </h2>
              <TagList tags={tags}/>
            </section>
          )}
        </div>
        <hr/>
        <div className="filters">
          {filters.map(({group, tags}) =>
            <FilterGroup key={group} title={group} tags={tags}/>
          )}
        </div>
      </nav>
    )
  }
}

class Typology extends Component{
  constructor(props){
    super(props)

    this._bound = ['kindSelected']
    this.state = {kind:this.props.kind}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  kindSelected(e){
    let kind = e.target.value
    this.setState({kind})
    this.props.onSelection(kind)
  }

  componentDidUpdate(prevProps){
    if (prevProps.kind != this.props.kind){
      this.setState({kind:this.props.kind})
    }
  }

  render(){
    let {kinds, types} = this.props,
        articles = _.filter(kinds, {subset:'articles'}),
        resources = _.filter(kinds, {subset:'resources'}),
        selected = _.find(kinds, {kind:this.state.kind});

    return (
      <div id="typology">
        <div className="menu">
          <select value={selected.kind} onChange={this.kindSelected}>
            {articles.map( ({kind, title}) =>
              <option key={kind} value={kind} disabled={!types[kind]}>{title}</option>
            )}
            <optgroup label="Key Program Documents">
            {resources.map( ({kind, title}) =>
              <option key={kind} value={kind} disabled={!types[kind]}>{title}</option>
            )}
            </optgroup>
          </select>
          <span className="kind" onClick={()=>this.props.onSelection(selected.kind)}>{selected.title}</span>
        </div>
      </div>
    )
  }
}

module.exports = Filters