import _ from 'lodash'
import React, {Component, Fragment} from "react"
import CSSTransitionGroup from 'react-addons-css-transition-group'
import TransitionGroup from 'react-addons-transition-group'
import classnames from 'classnames'
import {ignore, getJSON} from '../helpers.js'

class SearchBar extends Component{
  constructor(props){
    super(props)
    this._bound = ['expand', 'dismiss', 'search', 'queryChanged']
    this.state = {expanded:this.props.expanded, query:'', hits:{}}
    this.fetch = null
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  expand(e){
    if (!this.state.expanded)
      this.setState({expanded:true})
    setTimeout(()=>{
      document.getElementsByName('q')[0].focus()
    }, 500)
  }

  dismiss(e){
    if (e.type=='click' || e.type=='blur' && !this.state.query.trim()){
      this.setState({expanded:false, query:'', hits:{}})
    }
  }

  queryChanged(e){
    let query = e.target.value.replace(/^\s*/,'').replace(/[\W\s]+/g,' ')
    this.setState({query})

    if (query.trim()){
      this.setState({expanded:true})
      clearTimeout(this.fetch)
      this.fetch = setTimeout(this.search, 300)
    }
  }

  search(){
    let queryURI = this.state.query.trim().replace(/ /g, '+').toLowerCase()
    if (queryURI) getJSON(`/api/search/${queryURI}`, (err, resp) => {
      if (err || resp.q!=queryURI) return

      let hits = _.groupBy(resp.articles.slice(0, 5), 'kind')
      hits.more = resp.articles.length>5
      this.setState({hits})
    })
  }

  render(){
    let {expanded, query, hits} = this.state
    let queryURI = this.state.query.trim().replace(/ /g, '+').toLowerCase()

    return (
      <div className={classnames("search-bar", {expanded})} onClick={this.expand}>
        <input type="text" name="q" placeholder="Search…" autoComplete="off" value={query} onChange={this.queryChanged} onBlur={this.dismiss}/>
        {expanded && query &&
          <div className='top-hits'>
            <h1><span className="cancel" onClick={this.dismiss}></span>Search for ‘{query}’</h1>

            {hits['surveys'] && <Fragment>
              <h2>Surveys</h2>
              <ul>
              {hits['surveys'].map(a =>
              <li key={a.slug}>
                <a href={`/docs/${a.slug}`}>{a.title}</a>
              </li> )}
              </ul>
              </Fragment>
            }

            {hits['case-studies'] && <Fragment>
              <h2>Case Studies</h2>
              <ul>
              {hits['case-studies'].map(a =>
              <li key={a.slug}>
                <a href={`/docs/${a.slug}`}>{a.title}</a><br/>
                {a.location}, {a.date}
              </li> )}
              </ul>
              </Fragment>
            }

            {hits.more && <a className="more" href={`/find/all?q=${queryURI}`}>More…</a>}
          </div>
        }
      </div>

    )
  }
}

class SearchField extends Component{
  constructor(props){
    super(props)
    this._bound = ['queryChanged', 'queryCleared']
    this.state = {}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  queryChanged(e){
    let query = e.target.value.replace(/^\s*/,'').replace(/[\W\s]+/g,' ').replace(/\s+/g,'+')
    this.props.onSearch(query)
  }

  queryCleared(){
    this.props.onSearch(null)
  }

  render(){
    let val = (this.props.q || '').replace(/\+/g, ' ')
    return (
      <div className={classnames("search-field")}>
        <input type="text" name="q" placeholder="Search…" autoComplete="off" value={val} onChange={this.queryChanged}/>
        <span className="cancel" onClick={this.queryCleared}></span>
      </div>
    )
  }
}


module.exports = {SearchBar, SearchField}