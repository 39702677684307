import _ from 'lodash'
import React, {Component, Context, Fragment, createRef} from "react"
import ReactDOM from 'react-dom'
import CSSTransitionGroup from 'react-addons-css-transition-group'
import classnames from 'classnames'
import {ignore, getJSON, postJSON} from '../helpers.js'

class UserBar extends Component{
  constructor(props){
    super(props)
    this._bound = ['prompt', 'submit', 'cancel']
    this.state = {dialogue:false, user:{}}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  prompt(){
    this.setState({dialogue:true})
  }

  submit(response){
    this.setState({dialogue:!response.auth})
    this.props.onAuth()
  }

  cancel(){
    this.setState({dialogue:false})
  }

  render(){
    return (
      <div className="user">
        <UserMenu user={this.props.user} onPrompt={this.prompt} onLogout={this.props.onAuth}/>
        <UserModal hidden={!this.state.dialogue} onSubmit={this.submit} onCancel={this.cancel}/>
      </div>
    )
  }
}

class UserMenu extends Component{
  constructor(props){
    super(props)
    this._bound = ['logout', 'toggleMenu']
    this.state = {collapsed:true}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  toggleMenu(){
    this.setState(prev=>({collapsed:!prev.collapsed}))
  }

  logout(){
    postJSON('/api/user', {bye:true}, (err, response) => {
      this.props.onLogout()
      this.setState({collapsed:true})
    })
  }

  render(){
    let {user} = this.props,
        {collapsed} = this.state

    return _.isEmpty(user) ? (
      <div className="account log-in" onClick={this.props.onPrompt}>Log in</div>
    ) : (
      <div className={classnames("account", {collapsed})}>
        <h1 onClick={this.toggleMenu} onMouseDown={ignore}>
          {!!(user && user.id) && <img width="35" height="35" src={`/api/user/thumbnail/${user.id}.jpg`}/>}
          <span>{user.firstname} {user.lastname}</span>
        </h1>
        <ul>
          {user.settings!==false && <li><a href="/settings">User Settings</a></li>}
          <li onClick={this.logout}>Log out</li>
        </ul>
      </div>
    )

  }
}

class UserModal extends Component{
  constructor(props){
    super(props)
    this._bound = ['dismiss', 'submit', 'catchReturnKey', 'unfail']
    this._refs = ['user', 'pass']
    this.state = {failed:false}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
    _.each(this._refs, m => this[m] = createRef())
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hidden != this.props.hidden){
      this.setState({failed:false})
      if (!this.props.hidden){
        setTimeout(()=>this.user.current.focus(), 333)
      }
    }
  }

  dismiss(e){
    let dialogue = document.getElementById('dialogue')
    if (dialogue.contains(e.target)) return
    this.props.onCancel()
    ignore(e)
  }

  catchReturnKey(e){
    if (e.key=='Enter'){
      if (e.target==this.user.current) this.pass.current.focus()
      else this.submit()
    }
  }

  unfail(){
    this.setState({failed:false})
  }

  submit(){
    let user = this.user.current.value,
        pass = this.pass.current.value;

    this.setState({failed:false})
    postJSON('/api/user', {user, pass}, (err, response) => {
      this.setState({failed:!response.auth})
      if (!response.auth) this.pass.current.focus()
      this.props.onSubmit(response)
    })


  }

  render(){
    let {failed} = this.state,
        credentials = _.map([this.user.current, this.pass.current], f=>_.get(f, 'value', '').trim()),
        incomplete = _.size(_.compact(credentials)) < 2;

    let dialogue = (
      <CSSTransitionGroup transitionName="modal-display" transitionEnterTimeout={666} transitionLeaveTimeout={666}>
        {this.props.hidden ? null :
          <div className={classnames("user-modal")} onClick={this.dismiss}>
            <div className={classnames("user-authentication", {failed, incomplete})} id="dialogue">
              <h1>Log in to The New Bagehot Project</h1>
              <div className='fields'>
                <label htmlFor='user-name'>Your Email Address</label>
                <input ref={this.user} id='user-name' type="email" onKeyPress={this.catchReturnKey} onInput={this.unfail}/>
                <label htmlFor='user-password'>Password</label>
                <input ref={this.pass} id='user-password' type='password' onKeyPress={this.catchReturnKey} onInput={this.unfail}/>
              </div>
              <div className="account log-in" onClick={this.submit}>Log in</div>
              <p>Please <a href="mailto:help@example.com">contact us</a> if you are interested in contributing to The New Bagehot Project</p>
            </div>
          </div>
        }
      </CSSTransitionGroup>
    )

    let elt = (typeof document=='object') ? document.getElementById('modal-root') : null
    return elt ? ReactDOM.createPortal(dialogue, elt) : dialogue
  }
}


class UserSettings extends Component{
  constructor(props){
    super(props)
    this.state = {user:null, info:{firstname:'', lastname:'', email:''}, invalid:[], badAddress:'', largePhoto:false,
                  auth:{pass:'', newpass:'', confirm:''}, status:{retry:false, weak:false, typo:false, notready:true}, wrongPass:'',
                  hidden:null}
    this.labels = {
      firstname:"First name",
      lastname:"Last name",
      email:'Email address'
    }
    this.fields = _.keys(this.state.info)

    this._bound = ['updateInfo', 'updateAuth', 'updatePhoto', 'updatePrivacy', 'submitInfo', 'submitAuth', 'submitPrivacy']
    this._refs = this.fields.concat(['oldpass', 'newpass', 'confirm', 'photo'])
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
    _.each(this._refs, m => this[m] = createRef())
  }

  componentDidMount(){
    getJSON('/api/user', (err, resp) => {
      if (_.isEmpty(resp)) this.goHome()
      else this.setState(prev => ({user:resp, info:_.extend(prev.info, _.pick(resp, this.fields)), hidden:resp.hidden}))
    })
  }
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.auth, this.state.auth) || prevState.wrongPass!=this.state.wrongPass){
      this.validateAuth()
    }

    if (!_.isEqual(prevState.info, this.state.info) || prevState.badAddress!=this.state.badAddress){
      this.validateInfo()
    }
  }

  goHome(){
    window.location = '/'
  }

  updateInfo(){
    let info = _.fromPairs(this.fields.map(attr => [attr, this[attr].current.value]))
    this.setState({info})
  }

  validateInfo(){
    let {info, badAddress} = this.state,
        invalid = _.compact(_.map(this.fields, attr => {
          let val = info[attr],
              isEmail = attr=='email';
          return isEmail && !val.trim().match(/\w+@\w+\.\w+/) ? attr
               : isEmail && val == badAddress ? attr
               : !isEmail && _.size(val.trim())==0 ? attr
               : null
        }));
    this.setState({invalid})
  }

  submitInfo(){
    if (_.some(this.state.invalid)) return

    postJSON('/api/user/info', this.state.info, (err, resp) => {
      if (resp.ok)
        return location.reload()

      if (resp.conflict)
        this.setState({badAddress:this.state.info.email})

    })
  }

  updatePhoto(e){
    let img = _.first(e.target.files),
        reader = _.extend(new FileReader(), {
          onerror: (err) => {
            console.error(err)
          },
          onload: () => {
            postJSON(`/api/user/thumbnail/${this.state.user.id}.jpg`, {thumbnail:reader.result}, (err, resp) => {
              if (err==413) this.setState({largePhoto:true})
              else location.reload()
            })
          }
        })
    reader.readAsDataURL(img)
  }

  updateAuth(){
    let pass = this.oldpass.current.value,
        newpass = this.newpass.current.value,
        confirm = this.confirm.current.value;
    this.setState({auth:{pass, newpass, confirm}})
    this.validateAuth()
  }

  updatePrivacy(e){
    this.setState({hidden:e.target.value=='private'})
  }

  submitPrivacy(){
    let {hidden} = this.state
    postJSON('/api/user/privacy', {hidden}, (err, resp) => {
      if (resp.ok)
        return location.reload()
    })
  }

  validateAuth(){
    let focused = document.activeElement,
        {auth, wrongPass} = this.state,
        {pass, newpass, confirm} = auth;
    this.setState({status:{
      retry:focused!=this.oldpass.current && pass.trim() && pass==wrongPass,
      weak:focused!=this.newpass.current && newpass.trim() && newpass.length < 8,
      typo:focused!=this.confirm.current && newpass.trim() && confirm.trim() && confirm!==newpass,
      notready:!pass.trim() || !newpass.trim() || confirm!==newpass
    }})
  }

  submitAuth(){
    let {status, user, auth} = this.state,
        {pass, newpass} = auth;

    if (status.notready || status.weak) return

    postJSON('/api/user/auth', {pass, newpass}, (err, resp) => {
      if (resp.ok)
        return location.reload()

      if (resp.wrong){
        this.setState({wrongPass:resp.wrong})
        this.updateAuth()
      }
    })
  }

  render(){
    let {user, info, hidden, invalid, status, badAddress, largePhoto} = this.state,
        infoUnchanged = _.isMatchWith(user, info, (u, i) => u==i.trim()),
        privUnchanged = _.get(user, 'hidden') == hidden,
        collision = badAddress && info.email==badAddress,
        nocache = `?img=${+new Date()}`;

    return (
      <div className="user">
        <nav>
          <h1><a href="/">The New Bagehot Project</a></h1>
          <UserBar user={_.extend({settings:false}, user)} onAuth={this.goHome}/>
        </nav>
        <div className="settings">
          <h1>User Settings</h1>
          <div className="photo">
            {!!(user && user.id) && <img width="100" height="100" src={`/api/user/thumbnail/${user.id}.jpg${nocache}`}/>}
            <span onClick={(e) => this.photo.current.click() } className={classnames({overlarge:this.state.largePhoto})}>
              Upload photo
              <input ref={this.photo} type="file" accept="image/jpeg,image/png" onChange={this.updatePhoto}/>
            </span>
          </div>
          <div className="details">
            <form name="info">
              {this.fields.map(attr => (
                <Fragment key={attr}>
                  <label htmlFor={attr} className={classnames({conflict:attr=='email' && collision})}>{this.labels[attr]}</label>
                  <input ref={this[attr]} id={attr} className={classnames({invalid:_.includes(invalid, attr)})} value={info[attr]} onChange={this.updateInfo}/>
                </Fragment>
              ))}
              <input type='button' value="Update info" disabled={_.some(invalid) || infoUnchanged} onClick={this.submitInfo}/>
            </form>
            <form name="auth">
              <h1>Change Password</h1>
              <label htmlFor='old-password' className={classnames({retry:status.retry})}>Old password</label>
              <input ref={this.oldpass} id='old-password' type='password' onChange={this.updateAuth} className={classnames({invalid:status.retry})}/>
              <label htmlFor='new-password' className={classnames({weak:status.weak})}>New password</label>
              <input ref={this.newpass} id='new-password' type='password' onChange={this.updateAuth} onBlur={this.updateAuth} className={classnames({invalid:status.weak})}/>
              <label htmlFor='confirm-password' className={classnames({typo:status.typo})}>Confirm new password</label>
              <input ref={this.confirm} id='confirm-password' type='password' onChange={this.updateAuth} onBlur={this.updateAuth} className={classnames({invalid:status.typo})}/>
              <input type='button' value="Update password" disabled={status.notready || status.weak} onClick={this.submitAuth}/>
            </form>

            <form name="privacy">
              <h1>Privacy</h1>
              <label><input type="radio" name="privacy" value="public" checked={!hidden} onChange={this.updatePrivacy}/> Annotations will be visible to all logged in users</label><br/>
              <label><input type="radio" name="privacy" value="private" checked={!!hidden} onChange={this.updatePrivacy}/> Annotations can only be seen by New Bagehot Project staff</label><br/>
              <input type='button' value="Update privacy" disabled={privUnchanged} onClick={this.submitPrivacy}/>
            </form>

          </div>
        </div>
      </div>
    )
  }
}

module.exports = {UserBar, UserModal, UserSettings}