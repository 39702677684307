import _ from 'lodash'
import React, {Component} from "react"
import classnames from 'classnames'
import {ignore, later, scrollPage} from '../helpers.js'

class TableOfContents extends Component{
  constructor(props){
    super(props)
    this._bound = ['openSection', 'scanToC', 'updateToC', 'measureToC', 'goBack']
    this.docSections = []
    this.state = {ToC:[], back:'/'}
  }

  componentWillMount(){
    _.each(this._bound||[], m => this[m] = this[m].bind(this))
  }

  componentDidMount(){
    this.scanToC()
    $(window).on('scroll', this.updateToC)
    $('article').on('disclosure', this.measureToC)
    // $('.case-studies').on('comment-toggle', this.scanToC)

    let ref = new URL(document.referrer || location.origin),
        sameOrigin = document.referrer && ref.host == location.host;
    this.setState({sameOrigin, back:sameOrigin ? ref.pathname : '/find/all'})
  }

  componentWillUnmount() {
    $(window).off('scroll', this.updateToC)
    $('article').off('disclosure', this.measureToC)
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.user != this.props.user){
      later(this.scanToC)
    }
  }


  scanToC(){
    let docHeadings = $('section > h1, section > h2, section h2[id], section.kdd > h3')
    this.docSections = _.map(docHeadings, (elt,i) => ({
      title:elt.textContent.replace(/\s*\d*\s*$/,''),
      id:$(elt).closest('[id]').attr('id'),
      elt:elt
    }))
    this.measureToC()
  }

  measureToC(e){
    _.each(this.docSections, item => {
      let elt = item.elt,
          parent = elt.offsetParent,
          collapsed = $(elt).closest('.expandable').hasClass('collapsed'),
          top = parent.offsetTop + elt.offsetTop,
          bot = top + elt.offsetHeight,
          hidden = elt.parentNode.className=='content' && collapsed;
      _.extend(item, {top, bot, hidden})
    })
    this.updateToC()
  }

  updateToC(e){
    let top = document.documentElement.scrollTop || document.body.scrollTop
    this.setState({
      ToC:_.eachRight(_.cloneDeep(this.docSections), (elt, i) => {
        if(i==0 || elt.top < top + 50){
          elt.active = true
          return false
        }
      })
    })
  }

  openSection(e){
    let href = $(e.target).attr('href'),
        dest = $(href);
    dest.closest('.expandable').removeClass('collapsed')
    ignore(e)

    if (_.some(dest)) scrollPage({
      to:dest,
      complete: () => dest.trigger('disclosure')
    })
  }

  goBack(e){
    // go back if referrer is local, otherwise follow href
    if (this.state.sameOrigin){
      history.back()
      ignore(e)
    }
  }

  render(){
    let {dimmed, nocomment} = this.props,
        {ToC, back} = this.state,
        current = null

    let titles = _.filter(_.map(ToC, item => {
      if (nocomment && item.id=='comments')
        return null
      if (item.elt.tagName=='H1'){
        current = _.extend({subsections:[]}, item)
        return current
      }
      if (!item.hidden)
        current.subsections.push(item)
      if (item.active)
        current.active = true
      else if (current.active && !_.find(current.subsections, 'active'))
        (current.subsections[0] || {}).active = true
    }))

    let section = ({id, title, subsections, active}) => (
      <li key={id} className={classnames({active, sep:id=='comments'})}>
        <a href={`#${id}`} onClick={this.openSection}>{title}</a>
        {subsection(subsections)}
      </li>
    )

    let subsection = subs => (
      _.isEmpty(subs) ? null : (
        <ol className={_.find(subs, 'active') ? 'visible' : null}>
          {subs.map( ({id, title, active}) =>
            <li key={id} className={classnames({active})}>
              <a href={`#${id}`} onClick={this.openSection}>{title}</a>
            </li>
          )}
        </ol>
      )
    )

    return(
      <div className={classnames('toc', {dimmed})}>
        <h1><a href="/">The New Bagehot Project</a></h1>
        <h2><a href={back} onClick={this.goBack}>Back to list</a></h2>
        <ol className="sections">

          {titles.map(({id, title, subsections:subs, active}, i) => (
            <li key={`${id}-${i}`} className={classnames({active, sep:id=='comments'})}>
              <a href={`#${id}`} onClick={this.openSection}>{title}</a>

              {_.some(subs) && (
                <ol className={classnames("subsections", {visible:_.find(subs, 'active')})}>
                  {subs.map( ({id, title, active}, j) =>
                    <li key={`${id}-${j}`} className={classnames({active})}>
                      <a href={`#${id}`} onClick={this.openSection}>{title}</a>
                    </li>
                  )}
                </ol>
              )}

            </li>
          ))}





        </ol>
      </div>
    )
  }
}

module.exports = TableOfContents