import $ from 'cash-dom'
import _ from 'lodash'
import React, {Component} from "react";
import ReactDOM from "react-dom";

import helpers from './helpers.js'
import App from './ui/App.js';
import Publication from './ui/Publication.js'
import Matrix from './ui/Matrix.js'
import {UserSettings} from './ui/User.js'

$(function(){
  // expose cash as a global for use by the other components
  window.$ = $

  let page = $('body'),
      app = $("#new-bagehot")[0],
      usr = $("#nbp-settings")[0],
      pub = $("#publication")[0],
      data = page.data();
  _.each(data, (v,k) => page.attr('data-'+k, null)) // clean up dom

  if (data.taxonomy) _.extend(helpers, {tagtypes:() => ({
    actions:_(data.taxonomy).filter({subset:'actions'}).flatMap('tags'),
    filters:_(data.taxonomy).filter({subset:'filters'}).flatMap('tags')
  })})


  app && ReactDOM.hydrate(<App {...data}/>, app) // sync with serverside state
  usr && ReactDOM.render(<UserSettings/>, usr) // handle account modifications
  pub && ReactDOM.render(<Publication {...data}/>, pub) // provide ToCs & annotations
  Matrix('#matrix')
})

