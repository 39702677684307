import _ from 'lodash'
import React, {Component} from "react"
import CSSTransitionGroup from 'react-addons-css-transition-group'
import TransitionGroup from 'react-addons-transition-group'
import classnames from 'classnames'
import {ignore, parseTags, matchedQuery, slugify} from '../helpers.js'

const COMPARE_LIMIT = 6


class Citations extends Component{
  constructor(props){
    super(props)
    this._bound = []
    this.state = {classes:null, style:null}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  componentWillEnter(done){
    let initStyle = {display:'none'},
        fromStyle = {display:'block'},
        fromClass = 'refs-update-enter',
        toClass = `${fromClass} ${fromClass}-active`,
        dur = 300, tick = 20;

    this.setState({style:initStyle})
    setTimeout( () => {
      this.setState({classes:fromClass, style:fromStyle})
      setTimeout( () => {
        this.setState({classes:toClass})
        setTimeout(done, dur)
      }, tick)
    }, dur)
  }
  componentDidEnter(){
    this.setState({classes:null, style:null})
  }

  componentWillLeave(done){
    let fromStyle = {opacity:1},
        toStyle = {opacity:0},
        fromClass = 'refs-update-leave',
        toClass = `${fromClass} ${fromClass}-active`,
        dur = 300, tick = 20;

    this.setState({classes:fromClass})
    setTimeout(() => {
      this.setState({classes:toClass})
      setTimeout(done, dur)
    }, tick)
  }


  render(){
    if (!this.props.cites) return null

    const citation = (cite) => (
      _.has(cite, 'links') ? (
        <li key={cite.title}>
          <p>{cite.title}</p>
          <ul>
            {cite.links.map(link =>
              <li key={link.caption}><a href={link.url}>{link.caption}</a></li>
            )}
          </ul>
        </li>
      ) : (
        <li key={cite.title}>
          <p><a href={cite.url}>{cite.title}</a></p>
          {cite.caption && <p className="caption">{cite.caption}</p>}
        </li>
      )
    )

    return (
      <ul className={classnames("citations", this.state.classes)} style={this.state.style}>
        {this.props.cites.map( cite => citation(cite) )}
      </ul>
    )

  }
}


class Doc extends Component{
  constructor(props){
    super(props)
    this._bound = ['setDomRef']
    this._queue = []
    this.state = {classes:null, style:null}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){
    _.each(this._queue, id => clearTimeout(id))
  }
  componentDidUpdate(prevProps, prevState) {}

  setDomRef(node){
    this.dom = node
  }

  enqueue(callback, timeout=20){
    let id = setTimeout( () => {_.pull(this._queue, id); callback()}, timeout)
    this._queue.push(id)
  }

  componentWillEnter(done){
    let dom = $(this.dom),
        // height = this.props.article.resources ? dom.innerHeight() : 140;
        height = dom.innerHeight();

    let fromClass = `search-update-enter`,
        toClass = `${fromClass} ${fromClass}-active`,
        fromStyle = {maxHeight:'0px'},
        toStyle = {maxHeight:`${height}px`};

    this.setState({classes:fromClass, style:fromStyle})
    this.enqueue(() => {
      this.setState({classes:toClass, style:toStyle})
      this.enqueue(done, 666)
    })
  }
  componentDidEnter(){
    this.setState({classes:null, style:null})
  }

  componentWillLeave(done){
    let dom = $(this.dom),
        // height = this.props.article.resources ? dom.innerHeight() : 140;
        height = dom.innerHeight();

    let fromClass = `search-update-leave`,
        toClass = `${fromClass} ${fromClass}-active`,
        fromStyle = {maxHeight:`${height}px`},
        toStyle = {maxHeight:'0px'};

    this.setState({classes:fromClass, style:fromStyle})
    this.enqueue(() => {
      this.setState({classes:toClass, style:toStyle})
      this.enqueue(done, 666)
    })
  }
  componentDidLeave(){}

  render(){
    let {slug, kind, category, title, location, date, resources, compare} = this.props.article,
        {compared, incomparable} = this.props,
        included = !!this.props.compared;

    return (
      <li className={classnames({compared, incomparable}, this.state.classes)} style={this.state.style} ref={this.setDomRef}>
        <header>
          <div className="category">{category}</div>
          <div className="title"><a href={`/docs/${slug}`}>{title}</a></div>
          {kind=='case-studies' && <div className="dateline">{location}, {date}</div>}
        </header>
        {!resources && _.some(compare) && <div className={classnames("compare", {included})} onClick={()=>this.props.onCompare(slug)} onMouseDown={ignore}>Compare</div>}
        <TransitionGroup component='div' className="resources">
          <Citations key={this.props.kind} cites={resources}/>
        </TransitionGroup>
      </li>
    )
  }
}


class Results extends Component{
  constructor(props){
    super(props)
    this._bound = ['toggleComparison', 'clearComparison', 'viewComparison']
    this.state = {compared:[]}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  toggleComparison(slug){
    this.setState(prev => {
      if (_.includes(prev.compared, slug)){
        return {compared:_.without(prev.compared, slug)}
      }else if (prev.compared.length < COMPARE_LIMIT){
        return {compared:_.concat(prev.compared, slug)}
      }
    })
  }

  clearComparison(e){
    this.setState({compared:[]})
  }

  viewComparison(e){
    let {articles} = this.props.corpus,
        {compared} = this.state,
        subset = _.filter(articles, a => _.includes(compared, a.slug)),
        interventions = _.uniq(_.flatMap(subset, 'compare'));

    let query = `/compare/${_.first(interventions)}/${_.join(_.map(subset, 'slug'), '+')}`
    window.location = query
  }

  render(){
    let {mode, typology} = this.props,
        {kind, articles} = this.props.corpus,
        {compared} = this.state,
        rsrcType = _.find(typology, {kind:kind, subset:'resources'}),
        query = matchedQuery(this.props.query, articles, 'title');


    let comparable = _(articles).flatMap( ({slug, compare}) => {
      if (_.includes(compared, slug) || _.isEmpty(compared)) return compare
    }).compact().uniq().value()

    let incomparable = _(articles).map( ({slug, compare}) => {
      if (_.size(compared)==COMPARE_LIMIT && !_.includes(compared, slug))
        return slug

      if (_.some(compared) && _.isEmpty(_.intersection(comparable, compare)))
        return slug
    }).compact().value()

    // winnow the corpus down to query matches and group by doctype
    let matches = _(articles).filter( ({slug, tags}) => {
      let actionMatch = _.some(_.intersection(tags, query.actions)),
          filterMatch = _.some(_.intersection(tags, query.filters));
      return _.includes(compared, slug) ? true
           : _.isEmpty(query.all) ? true
           : _.isEmpty(query.actions) ? filterMatch
           : _.isEmpty(query.filters) ? actionMatch
           : actionMatch && filterMatch
    }).groupBy('kind').value();

    // merge in typology title strings & count results
    let doctypes = _(typology).filter({subset:'articles'}).tail().map(({kind, title}) => {
      let heading = (rsrcType && kind=='case-studies') ? rsrcType.title : title,
          count = _.sumBy(matches[kind], doc => _.has(doc, 'resources') ? _.size(doc.resources) : 1);
      return {heading, count, key:title, docs:matches[kind], hidden:!count}
    }).value()

    return(
      <CSSTransitionGroup component="aside" id="results" transitionName="listing-display" transitionEnterTimeout={666} transitionLeaveTimeout={666}>
        {this.props.children}
        {mode=='find' &&
          <div className="listing">
            {doctypes.map( ({heading, key, docs, count, hidden}) =>
            <section key={key} className={classnames('group', slugify(key), {hidden})}>
              <h1>{heading} <span className="count">{count}</span></h1>
              <TransitionGroup component='ul' className="matches">
              {!hidden && docs.map((item) =>
                <Doc key={item.slug} kind={kind} article={item} onCompare={this.toggleComparison} compared={_.includes(compared, item.slug)} incomparable={_.includes(incomparable, item.slug)} />
              )}
              </TransitionGroup>
            </section>
            )}
            <section className={classnames('group', {hidden:!_.every(doctypes, 'hidden')})}>
              <h1>No Results</h1>
            </section>
            <div id="comparison" className={classnames({pending:_.some(compared), ready:_.size(compared)>1})}>
              <span>Select up to {COMPARE_LIMIT} Case Studies in the same Intervention Type</span><br/>
              <a className="compare" onClick={this.viewComparison}>Compare</a>&nbsp;<a onClick={this.clearComparison}>Cancel</a>
            </div>
          </div>
        }
      </CSSTransitionGroup>

    )
  }
}

module.exports = Results