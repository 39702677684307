import _ from 'lodash'
import markdown from 'markdown-it'
import React from "react"
import ReactDOM from "react-dom"

// inline the handlebars 'safe string' type
function SafeString(string){ this.string=string; }
SafeString.prototype.toString = SafeString.prototype.toHTML = function(){ return ''+this.string; };

class Portal extends React.Component {
  render() {
    let {children, node, unless} = this.props,
        canUseDOM = typeof window !== 'undefined' && !!_.get(window, 'document.createElement')
    return (canUseDOM && !!node && !unless) ? ReactDOM.createPortal(children, node) : null
  }
}

const md = new markdown({html:true, typographer:true, breaks:true}),
      SAFE = str => new SafeString(typeof str=='object' ? str.join(' ') : str),
      MD = src => SAFE(md.render(src)),
      XHR = callback => _.extend(new XMLHttpRequest(), {
        onload(){
          let {status, responseText} = this,
              err = status < 200 || status >= 400,
              data = !err && JSON.parse(responseText);
          if (err) callback(status)
          else callback(null, data)
        },
        onerror(){ callback('xhr-error') }
      }),
      rAF = (function() {
        var window = (typeof window=='undefined') ? {} : window
        let timeLast = 0,
            timer = (callback) => {
              let timeCurrent = (new Date()).getTime(),
                  timeDelta = Math.max(0, 16 - (timeCurrent - timeLast));
              timeLast = timeCurrent + timeDelta;
              return setTimeout(callback, timeDelta, timeCurrent+timeDelta);
            };
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || timer
      })(),
      EASING = {
        easeInQuad: function (t, b, c, d) {     return c*(t/=d)*t + b },
        easeOutQuad: function (t, b, c, d) {    return -c *(t/=d)*(t-2) + b },
        easeInOutQuad: function (t, b, c, d) {  return ((t/=d/2) < 1) ? c/2*t*t + b
                                                                      : -c/2 * ((--t)*(t-2) - 1) + b },
        easeInCubic: function (t, b, c, d) {    return c*(t/=d)*t*t + b },
        easeOutCubic: function (t, b, c, d) {   return c*((t=t/d-1)*t*t + 1) + b },
        easeInOutCubic: function (t, b, c, d) { return ((t/=d/2) < 1) ? c/2*t*t*t + b
                                                                      : c/2*((t-=2)*t*t + 2) + b },
        easeInQuart: function (t, b, c, d) {    return c*(t/=d)*t*t*t + b },
        easeOutQuart: function (t, b, c, d) {   return -c * ((t=t/d-1)*t*t*t - 1) + b },
        easeInOutQuart: function (t, b, c, d) { return ((t/=d/2) < 1) ? c/2*t*t*t*t + b
                                                                      : -c/2 * ((t-=2)*t*t*t - 2) + b },
        easeInQuint: function (t, b, c, d) {    return c*(t/=d)*t*t*t*t + b },
        easeOutQuint: function (t, b, c, d) {   return c*((t=t/d-1)*t*t*t*t + 1) + b },
        easeInOutQuint: function (t, b, c, d) { return ((t/=d/2) < 1) ? c/2*t*t*t*t*t + b
                                                                      : c/2*((t-=2)*t*t*t*t + 2) + b },
        easeInSine: function (t, b, c, d) {     return -c * Math.cos(t/d * (Math.PI/2)) + c + b },
        easeOutSine: function (t, b, c, d) {    return c * Math.sin(t/d * (Math.PI/2)) + b },
        easeInOutSine: function (t, b, c, d) {  return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b },

        easeInCirc: function (t, b, c, d) {     return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b },
        easeOutCirc: function (t, b, c, d) {    return c * Math.sqrt(1 - (t=t/d-1)*t) + b },
        easeInOutCirc: function (t, b, c, d) {  return ((t/=d/2) < 1) ? -c/2 * (Math.sqrt(1 - t*t) - 1) + b
                                                                      :  c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b },
        easeInExpo: function (t, b, c, d) {     return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b },
        easeOutExpo: function (t, b, c, d) {    return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b },
        easeInOutExpo: function (t, b, c, d) {  return (t==0) ? b
                                                     : (t==d) ? b+c
                                                     : ((t/=d/2) < 1) ? c/2 * Math.pow(2, 10 * (t - 1)) + b
                                                     : c/2 * (-Math.pow(2, -10 * --t) + 2) + b }
      };

const helpers = {
  Portal,
  pretty: (obj) => console.log(JSON.stringify(obj, null, '  ')),
  len: lst => _.size(lst),
  inc: i => parseInt(i, 10) + 1,
  ignore: e => e && e.stopPropagation() || e.preventDefault(),
  isMobile: () => window.innerWidth < 1000,
  enqueue: (...args) => {
    const callbacks = _.isArray(args[0]) ? args[0] : args
    let next = () => setTimeout(() => {
      if (_.some(callbacks) && callbacks.shift()() !== false) next()
    }, 20)
    next()
  },
  idx2alpha: i => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i],
  later: callback => helpers.enqueue(callback),
  tween: function({at=0, to=1, dur=1000, ease='easeOutQuart', step=_.noop, complete=_.noop}){
    let since = new Date(),
        delta = to - at,
        interp = _.get(EASING, ease, EASING.easeOutQuart);

    let tween = {
      opts:{at, to, dur, ease},
      tick:function(){
        var now = new Date(),
            pos = Math.min(now-since, dur),
            val = interp(pos, at, delta, dur);

        // short circuit once the anim is asymptotic
        if (Math.round(val*1000)==Math.round(to*1000) || pos==dur)
          [val, pos] = [to, dur]

        if (step(val)!==false && pos < dur) rAF(tween.tick)
        else complete()
      }
    }

    rAF(tween.tick)
    return tween
  },
  scrollPage: ({to=0, dur=1000, complete=_.noop}) => {
    let at = document.documentElement.scrollTop || document.body.scrollTop
    to = _.hasIn(to, 'offset') ? to.offset().top - 20 : to // `to` can be a y value or an elt
    helpers.tween({
      at, to, dur, complete,
      step:(y) => {document.body.scrollTop = y; document.documentElement.scrollTop = y}
    })
  },
  getJSON: (url, callback) => {
    let request = XHR(callback)
    request.open('GET', url, true)
    request.send()
    return request
  },
  postJSON: (url, data, callback) => {
    let request = XHR(callback),
        payload = data instanceof Blob ? data : JSON.stringify(data);
    request.open('POST', url, true)
    request.setRequestHeader("Content-Type", "application/json");
    request.send(payload)
    return request
  },
  serialize: data => JSON.stringify(data).replace(/'/g, "\\u0027").replace(/^"|"$/g,''),
  entitled: t => _.filter([t, 'New Bagehot Project']).join(': '),
  ifdef: (a, undef) => a===undefined ? undef : a,
  first: (lst) => _.first(lst),
  markdown: txt => MD(_.toString(txt)),
  dateline: ({location, date}) => _.join(_.filter([location, date]), ', '),
  slugify: txt => txt.toLowerCase().trim()
                     .replace(/[^\w\+\-\/ ]/g, '')
                     .replace(/[ \+\-\_\/]+/g, '-')
                     .replace(/^(the|a(n)?)-/, ''),
  superscript: digits => {
    const supers = '⁰¹²³⁴⁵⁶⁷⁸⁹'
    return digits.toString().split('').map( d=>supers[parseInt(d)] ).join('')
  },
  data_attrs: obj => SAFE(_.map(obj, (v,k) =>
    `data-${k}='${helpers.serialize(v)}'`
  )),
  attrs: options => SAFE(_.filter(_.map(options.hash, (v,k) =>
    _.isEmpty(v) ? null : `${k}="${v}"` // only include attrs with truthy vals
  ))),
  tagtypes:()=>{ console.error('Error: tagtypes uninitialized') }, // {actions:[{title, slug}, …], filters:[…]}
  parseTags: (q, attr=_.identity) => {
    let {actions, filters} = helpers.tagtypes(),
        all = actions.concat(filters.value()),
        query = typeof q=='string' ? _.split(q, '+') : q || [],
        cmp = _.isEqual(query, query.map(_.toLower)) ? 'slug' : 'title',
        included = (tag) => _.includes(query, tag[cmp]);
    return {
      actions:actions.filter(included).map(attr).value(),
      filters:filters.filter(included).map(attr).value(),
      all:all.filter(included).map(attr).value()
    }
  },
  matchedQuery: (q, articles, attr) => {
    let {parseTags} = helpers,
        query = parseTags(q, 'title'),
        docs = _.map(articles, ({tags}) => parseTags(tags, 'title'))

    // only include actions & filters that yield at least one result
    query.actions = _(docs).flatMap('actions').intersection(query.actions).value()
    query.filters = _(docs).flatMap( doc => {
      let noActions = _.isEmpty(query.actions),
          actionMatch = _.some(_.intersection(doc.actions, query.actions));
      if (noActions || actionMatch) return doc.filters
    }).intersection(query.filters).value()

    return parseTags(_.concat(query.actions, query.filters), attr)
  },
  doctypes:()=>{ console.error('Error: doctypes uninitialized') }, // [{title, kind}, …]
  "each-doctype":function(context, options){
    return _.compact(_.map(helpers.doctypes(), ({kind, title}) => {
      var refs = context[kind],
          opts = {data:options.data, blockParams:[title, refs]};
      return _.isEmpty(refs) ? null : options.fn(this, opts)
    })).join('\n')
  },
  "each-kdd":function(context, options){
    let prev = {category:null, subcategory:null}

    return _.map(context, (kdd) =>{
      kdd.section = _.compact([kdd.category, kdd.subcategory]).join(': ')
      if (kdd.category == prev.category)
        _.unset(kdd, 'category')
      if (kdd.subcategory == prev.subcategory)
        _.unset(kdd, 'subcategory')

      prev = _.defaults(_.pick(kdd, 'category', 'subcategory'), prev)
      return options.fn(kdd)
    }).join('\n')
  },
  "matrix-label": options => {
    let {title, category, subtitle, survey} = options.hash,
        anchor = helpers.slugify(title || _.join(_.compact([category, subtitle]), '-'));

    return SAFE(`<aside>${_.compact([
      title && `<h1>${title}</h1>`,
      subtitle && `<h2>${subtitle}</h2>`,
      survey && `<a href="/docs/${survey}#${anchor}">Survey</a>`
    ]).join('\n')}</aside>`)
  },
  "matrix-cols": (cols, attr) => {
    let fields = _.isString(attr) ? _.map(cols, attr) : cols
    return SAFE(_.map(fields, s => `<section class="col">${MD(s)}</section>`).join('\n'))
  },
  "terms-rows":function(context, options){
    let labels = _.map(_.first(context).terms, 'key')
    return _.map(labels, (title, i) => {
      let cols = _.map(context, doc =>
        _.get(doc, `terms[${i}].val`).trim()
         .replace(/^N\/A$/, '~~N/A~~')
      )
      return options.fn(this, {data:options.data, blockParams:[title, cols]})
    }).join('\n')
  },
  "kdd-rows":function(context, options){
    let prev = {category:null},
        tree = [],
        kdds = _.first(context).decisions;

    _.each(kdds, (kdd, idx) => {
      let cols = _.map(context, col =>
        _.get(col, `decisions[${idx}].text`).trim()
         .replace(/^N\/A$/, 'Not Applicable')
         .replace(/^not applicable$/i, '~~Not Applicable~~')
      )

      if (!_.some(cols)) return

      if (kdd.category != prev.category){
        tree.push({
          title:kdd.category,
          row:kdd.subcategory ? 'category' : 'row',
          cols:kdd.subcategory ? _.fill(Array(cols.length), '') : cols,
          subcats:[]
        })
        prev.category = kdd.category
      }

      if (kdd.subcategory){
        _.last(tree).subcats.push({title:kdd.subcategory, cols})
      }
    })

    return _.map(tree, ({title, row, cols, subcats}) => {
      let opts = {data:options.data, blockParams:[title, row, cols, subcats]}
      return options.fn(this, opts)
    }).join('\n')
  }
}


module.exports = helpers