import _ from 'lodash'
import React, {Component, Fragment} from "react"
import ReactDOM, {createPortal} from "react-dom"
import CSSTransitionGroup from 'react-addons-css-transition-group'
import moment from 'moment-timezone'
import {SearchBar} from './Search.js'
import {UserBar} from './User.js'
import {ignore, getJSON, postJSON} from '../helpers.js'
import classnames from 'classnames'

class Marginalia extends Component{
  constructor(props){
    super(props)
    this._bound = ['openThread', 'interceptClick', 'didEdit', 'abortEditing', 'resumeEditing', 'didHighlight', 'saveEdits', 'closeThread']
    this.state = {focussed:false, viewing:false, editing:false, excerpt:"", clean:true, discard:false}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }
  componentDidMount(){}
  componentWillUnmount(){}

  componentDidUpdate(prevProps, prevState){
    // detect whether focus is trained on this note
    if (!_.isEqual(prevProps.focus, this.props.focus)){
      let {focus, note} = this.props,
          focussed = focus.id == note.id,
          viewing = focussed && focus.active,
          editing = viewing && this.empty;

      this.setState({focussed, viewing, editing})
    }

    // listen for outside clicks when viewer is visible (so it can be dismissed) and text selection (for excerpting)
    if (!_.isEqual(prevState.viewing, this.state.viewing)){
      let binding = this.state.viewing ? 'addEventListener' : 'removeEventListener'
      document[binding]('mousedown', this.interceptClick, false)
      document[binding]('selectionchange', this.didHighlight, false)
      $('body').toggleClass("viewing", this.state.viewing)
    }

    if (!_.isEqual(prevState.editing, this.state.editing)){
      $('body').toggleClass("editing", this.state.editing)
    }
  }

  interceptClick(e){
    let noteElt = this.props.note.elt,
        viewElt = this.viewer;

    if (!_.some([viewElt, noteElt], elt => elt.contains(e.target))){
      ignore(e)
      if (!this.state.editing) this.closeThread()
    }else{
      this.willHighlight = noteElt.contains(e.target)
    }
  }

  didHighlight(e){
    if (!this.willHighlight) return

    let {elt} = this.props.note,
        sel = window.getSelection(),
        rng = sel.rangeCount && sel.getRangeAt(0);

    let excerpt = (!rng || rng.collapsed) ? '' : rng.toString().trim()
    this.setState({excerpt})
  }

  openThread(e){
    this.props.onToggle({id:this.props.note.id, active:true})
    ignore(e)
  }

  closeThread(e){
    if (!this.state.clean){
      // make user abort or resume before discarding
      this.setState({discard:true})
    }else{
      this.setState({editing:false, discard:false})
      this.props.onToggle({id:this.props.note.id, active:false})
    }
  }

  didEdit(text){
    this.setState({editing:true, clean:!text.trim()})
  }

  saveEdits(text, cb){
    cb = cb || _.noop
    let {slug, note} = this.props,
        {excerpt} = this.state,
        graf = note.id;
    postJSON(`/api/article/${this.props.slug}/comments`, {slug, graf, text, excerpt}, (err, response) => {
      let ok = !err && response.ok


      if (ok){
        this.props.onAnnotation({ok:true})
        this.setState({editing:false, clean:true})
      }

      cb(ok)
    })
  }

  abortEditing(e){
    this.setState({clean:true, editing:false, discard:false})
    this.props.onToggle({id:this.props.note.id, active:false})
  }

  resumeEditing(e){
    this.setState({discard:false})
  }

  get empty(){
    return !_.size(this.props.note.comments)
  }

  render(){
    let {note, focus, user} = this.props,
        {focussed, viewing, editing, discard} = this.state,
        {id, elt, comments} = note,
        empty = this.empty;

    $(elt).toggleClass("slide", viewing)

    return viewing ? (
      <div className={classnames('viewer', {editing, confirm:discard})} ref={node => this.viewer=node}>
        <h1>Annotations <span className="cancel" onClick={this.closeThread}></span></h1>
        <Editor user={user} editing={editing} excerpt={this.state.excerpt} onEdit={this.didEdit} onSave={this.saveEdits} onDiscard={this.closeThread}/>

        <CSSTransitionGroup component="div" className="thread" transitionName="note-display" transitionEnterTimeout={666} transitionLeaveTimeout={666}>
          {_.map(_.orderBy(comments, 'date', 'desc'), ({user, text, excerpt, date},i) =>
            <Comment key={`${user._id}.${date}`} user={user} text={text} excerpt={excerpt} date={date}/>
          )}
        </CSSTransitionGroup>

        <div className="confirmation">
          <p>Your annotation has not been saved.<br/>Do you really wish to discard it?</p>
          <ul className="save-buttons">
            <li className="discard active" onClick={this.abortEditing}>Discard</li>
            <li className="keep active" onClick={this.resumeEditing}>Keep editing</li>
          </ul>
        </div>

      </div>
    ) : focussed || !empty ? (
      <div className={classnames('marker', {empty, inactivated:focus.active})} onClick={this.openThread} title="Add an annotation…"></div>
    ) : null
  }
}

class Comment extends Component{
  constructor(props){
    super(props)
    this._bound = []
    this.state = {}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  render(){
    let {user, graf, date, text, excerpt, context} = this.props

    if (!user || !text) return null

    return (
      <div className="post">
        <div className="byline">
          <img width="35" height="35" src={`/api/user/thumbnail/${user._id}.jpg`}/>
          <span className="user">{user.firstname}&nbsp;{user.lastname}</span><br/>
          {!!date && <span>{moment(date).format('MMM D, Y')}</span>}
        </div>
        {!!context &&
          <div className="context">
            <a href={`#${graf}`}>{context.sect}</a>
          </div>
        }
        {!!excerpt &&
          <div className="excerpt"><span>{excerpt}</span></div>
        }
        <p>{text}</p>
      </div>
    )
  }
}

class Editor extends Component{
  constructor(props){
    super(props)
    this._bound = ['beginEditing', 'updateText', 'saveEdits', 'discardEdits']
    this.state = {text:"", excerpt:"", busy:false}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  beginEditing(e){
    this.props.onEdit(this.state.text)
  }

  updateText(e){
    let text = e.target.value
    this.setState({text})
    this.props.onEdit(text)
  }

  saveEdits(e){
    this.setState(state => {
      if (!state.busy) this.props.onSave(state.text, success => {
        this.setState({busy:false, text:success ? '' : state.text})
      })
      return {busy:true}
    })
  }

  discardEdits(e){
    this.props.onDiscard()
  }

  render(){
    let {user, editing, excerpt} = this.props,
        {text, busy} = this.state,
        avatar = `/api/user/thumbnail/${user.id}.jpg`;

    return (
      <div className="editor">
      {!editing ? (
        <div className="begin" onClick={this.beginEditing}>
          <img width="35" height="35" src={avatar}/>
          <span>Write an annotation…</span>
        </div>
      ) : (
        <Fragment>
          <div className="byline">
            <img width="35" height="35" src={avatar}/>
            <span>{user.firstname}&nbsp;{user.lastname}</span>
          </div>
          <div className="excerpt">
            {!!excerpt && <span>{excerpt}</span>
                       || "← Highlight some text to quote a specific passage…"}
          </div>
          <textarea value={this.state.text} onChange={this.updateText} placeholder="Your feedback" autoFocus={true} />
          <ul className="save-buttons">
            <li className={classnames("discard", {active:!busy && !!text.trim()})} onClick={this.discardEdits}>Discard</li>
            <li className={classnames("keep", {active:!busy && !!text.trim()})} onClick={this.saveEdits}>Save annotation</li>
          </ul>
        </Fragment>
      )}
      </div>
    )

  }
}


class Annotations extends Component{
  constructor(props){
    super(props)
    this._bound = ['didEdit', 'saveEdits', 'discardEdits']
    this.state = {editing:false}
  }

  componentWillMount(){
    _.each(this._bound, m => this[m] = this[m].bind(this))
  }

  componentDidMount(){}
  componentWillUnmount(){}
  componentDidUpdate(prevProps, prevState) {}

  didEdit(){
    this.setState({editing:true})
  }

  saveEdits(text, cb){
    cb = cb || _.noop
    let {slug} = this.props

    postJSON(`/api/article/${slug}/comments`, {slug, text}, (err, response) => {
      let ok = !err && response.ok

      console.log('resp', response)
      if (ok){
        this.props.onAnnotation({ok:true})
        this.setState({editing:false})
      }

      cb(ok)
    })
  }

  discardEdits(){
    this.setState({editing:false})
  }

  render(){
    let comments = _(this.props.notes).flatMap('comments').concat(this.props.posts).compact().orderBy('date', 'desc').value(),
        {editing} = this.state,
        {user} = this.props
    return (
      <Fragment>
        <h1>Annotations</h1>
        <div className="content">
          <ul className="forum">
            <Editor user={user} editing={editing} onEdit={this.didEdit} onSave={this.saveEdits} onDiscard={this.discardEdits}/>
            {_.map(comments, ({user, text, excerpt, date, graf, context},i) =>
              <Comment key={`${user._id}.${date}`} user={user} text={text} excerpt={excerpt} date={date} graf={graf} context={context}/>
            )}
          </ul>
        </div>
      </Fragment>
    )
  }
}



module.exports = {Annotations, Marginalia}



